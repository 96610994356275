import { getParser as createBowserParser } from 'bowser';

const vendorPrefixByBrowserEngine = {
  WebKit: 'WebKit',
  Blink: 'WebKit',
  Gecko: 'Moz',
};

const browser =
  typeof window !== 'undefined' ? createBowserParser(window.navigator.userAgent) : null;
const engine = browser ? browser.getEngineName() : null;
const vendorPrefix: string = engine ? vendorPrefixByBrowserEngine[engine] : '';

export const METHOD_VENDOR_PREFIX = vendorPrefix.toLowerCase();
export const CSS_VENDOR_PREFIX = vendorPrefix ? `-${vendorPrefix.toLowerCase()}-` : '';

export const IS_MOBILE = browser ? browser.getPlatformType() === 'mobile' : 'false';
export const MOBILE_NATIVE_CONTROLS_MAX_HEIGHT = 40;
