import * as React from 'react';

import {
  ButtonFullSizeWrapper,
  MuteButton,
  MuteButtonWrapper,
  PlayButton,
  ReplayButton,
} from '../button';

interface IController {
  showPlayButton: boolean;
  showReplayButton: boolean;
  isMuted: boolean;
  showToggleMuteButton: boolean;
  play: () => void;
  replay: () => void;
  toggleMuted: () => void;
  featureAppId: string;
}

export const Controller: React.FC<IController> = ({
  showPlayButton,
  play,
  showReplayButton,
  replay,
  toggleMuted,
  isMuted,
  showToggleMuteButton,
  featureAppId,
}) => {
  return (
    <>
      {showPlayButton && (
        <ButtonFullSizeWrapper onClick={play}>
          <PlayButton />
        </ButtonFullSizeWrapper>
      )}
      {showReplayButton && (
        <ButtonFullSizeWrapper onClick={replay}>
          <ReplayButton />
        </ButtonFullSizeWrapper>
      )}
      {showToggleMuteButton && (
        <MuteButtonWrapper id={`${featureAppId}-videoAppMuteButtonWrapper`}>
          <MuteButton isMuted={isMuted} toggleMuted={toggleMuted} />
        </MuteButtonWrapper>
      )}
    </>
  );
};
