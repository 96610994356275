/* eslint-disable no-useless-escape */
import { useContext } from 'react';
import { TrackingDataV2 } from '@oneaudi/audi-tracking-service';
import { Context } from '../../Context';
import { getInstanceIndexNo } from '../../utils/getInstanceIndexNo';
import { getPercentageToTrack } from '../../utils/getPercentageToTrack';
import {
  debug,
  isTrackingDisabled,
  logVideoPlayerComponentTrackingData,
} from '../../utils/debugging';

export interface Tracking {
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
  videoStartEvent(): void;
  videoPercentagePlayedEvent(currentTime: number, duration: number): void;
  videoPauseEvent(): void;
  // videoSkippedEvent(): void;
  videoResumeEvent(): void;
  /*
  videoFullscreenToggledEvent(isFullscreenActive: boolean): void;
  videoSoundToggledEvent(isMuted: boolean): void;
  */
}

export function useTracking(
  ref: React.MutableRefObject<null>,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  if (isTrackingDisabled()) {
    debug('TRACKING DISABLED');

    return {
      sendReadyEvent: () => {
        debug('TRACKING not executed: sendReadyEvent');
      },
      registerImpressionTracking: () => {
        debug('TRACKING not executed: registerImpressionTracking');
      },
      videoStartEvent: () => {
        debug('TRACKING not executed: videoStartEvent');
      },
      videoPercentagePlayedEvent: () => {
        debug('TRACKING not executed: videoPercentagePlayedEvent');
      },
      videoPauseEvent: () => {
        debug('TRACKING not executed: videoPauseEvent');
      },
      videoResumeEvent: () => {
        debug('TRACKING not executed: videoResumeEvent');
      },
    };
  }

  const { trackingService } = useContext(Context);

  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        try {
          trackingService.track(readyEvent);
        } catch (e) {
          debug('sendReadyEvent ERROR', e);
          logVideoPlayerComponentTrackingData('sendReadyEvent');
        }
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        try {
          trackingService.registerImpressionTracking(ref.current, () => {
            return createVideoEvent(
              defaultComponentAdditions,
              componentNameWithDashes,
              componentNameWithSpaces,
              'impression',
              'impression',
              ref.current,
            );
          });
        } catch (e) {
          debug('registerImpressionTracking ERROR', e);
          logVideoPlayerComponentTrackingData('registerImpressionTracking');
        }
      }
    },
    videoStartEvent: () => {
      if (trackingService) {
        try {
          trackingService.track(
            createVideoEvent(
              defaultComponentAdditions,
              componentNameWithDashes,
              componentNameWithSpaces,
              'video',
              'start',
              ref.current,
            ),
          );
        } catch (e) {
          debug('videoStartEvent ERROR', e);
          logVideoPlayerComponentTrackingData('videoStartEvent');
        }
      }
    },
    videoPercentagePlayedEvent: (currentTime, duration) => {
      const percent = getPercentageToTrack(currentTime, duration);
      if (percent) {
        if (trackingService) {
          try {
            trackingService.track(
              createVideoEvent(
                defaultComponentAdditions,
                componentNameWithDashes,
                componentNameWithSpaces,
                'video',
                `${percent} percent`,
                ref.current,
              ),
            );
          } catch (e) {
            debug('videoPercentagePlayedEvent ERROR', e);
            logVideoPlayerComponentTrackingData('videoPercentagePlayedEvent');
          }
        }
      }
    },
    videoPauseEvent: () => {
      if (trackingService) {
        try {
          trackingService.track(
            createVideoEvent(
              defaultComponentAdditions,
              componentNameWithDashes,
              componentNameWithSpaces,
              'video',
              'pause',
              ref.current,
            ),
          );
        } catch (e) {
          debug('videoPauseEvent ERROR', e);
          logVideoPlayerComponentTrackingData('videoPauseEvent');
        }
      }
    },
    /*
    videoSkippedEvent: () => {
      console.log('>>>videoSkippedEvent (READY)');
    },
    */
    videoResumeEvent: () => {
      if (trackingService) {
        try {
          trackingService.track(
            createVideoEvent(
              defaultComponentAdditions,
              componentNameWithDashes,
              componentNameWithSpaces,
              'video',
              'resume',
              ref.current,
            ),
          );
        } catch (e) {
          debug('videoResumeEvent ERROR', e);
          logVideoPlayerComponentTrackingData('videoResumeEvent');
        }
      }
    },
    /*
    videoFullscreenToggledEvent: (isFullscreenActive: boolean) => {
      console.log('>>>videoFullscreenToggledEvent (READY)', isFullscreenActive);
    },
    videoSoundToggledEvent: (isMuted: boolean) => {
      // console.log('>>>videoSoundToggledEvent (READY)', isMuted);
    },
    */
  };
}

function createVideoEvent(
  defaultComponentAdditions,
  componentNameWithDashes: string,
  componentNameWithSpaces: string,
  eventAction: 'impression' | 'video',
  eventNamePart: string,
  videoElement: HTMLVideoElement,
): TrackingDataV2 {
  const event: TrackingDataV2 = {
    event: {
      attributes: {
        componentName: componentNameWithDashes,
        label: '',
        targetURL: videoUrlToTrackingId(videoElement.currentSrc),
        clickID: '',
        elementName: 'video',
        value: eventAction === 'impression' ? '' : secondstoHHMMSS(videoElement.duration),
        pos:
          eventAction === 'impression'
            ? getInstanceIndexNo(videoElement).toString()
            : secondstoHHMMSS(videoElement.currentTime),
      },
      eventInfo: {
        eventAction,
        eventName: `${componentNameWithSpaces} - ${eventNamePart}`,
      },
    },
    componentUpdate: defaultComponentAdditions,
  };

  if (eventAction === 'video') {
    if (eventNamePart === 'start' || eventNamePart === 'pause' || eventNamePart === 'resume') {
      event.event.attributes.actionType = videoElement.autoplay ? 'automatic' : 'click';
    } else if (eventNamePart.indexOf('percent') !== -1) {
      event.event.attributes.actionType = 'automatic';
    } else {
      event.event.attributes.actionType = 'click';
    }
  }

  return event;
}

/**
 * Transform a video asset URL to an id that meets tracking requirements ("Full URL of
 * the video, without https://"), see tracking quality gate
 * https://collaboration.msi.audi.com/confluence/x/QWp5JQ
 */
export function videoUrlToTrackingId(videoUrl: string) {
  if (videoUrl) {
    // remove protocol
    let transformedUrl = videoUrl.replace(/(^\w+:|^)\/\//, '');

    // add host name of current location.href if missing
    if (transformedUrl.startsWith('/')) {
      const currentUrl = new URL(window?.location.href);
      transformedUrl = `${currentUrl.hostname}${transformedUrl}`;
    }

    return transformedUrl;
  }

  return '';
}

export function secondstoHHMMSS(seconds: number | undefined) {
  if (!seconds) {
    return '00:00';
  }

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds - h * 3600) / 60);
  const s = Math.floor(seconds - h * 3600 - m * 60);

  const hStr = h < 10 ? `0${h}` : h;
  const mStr = m < 10 ? `0${m}` : m;
  const sStr = s < 10 ? `0${s}` : s;

  if (h === 0) {
    return `${mStr}:${sStr}`;
  }

  return `${hStr}:${mStr}:${sStr}`;
}
