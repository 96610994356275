import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as AudioOffLarge } from '../../assets/system-audio-off-large.svg';
import { ReactComponent as AudioOffSmall } from '../../assets/system-audio-off-small.svg';
import { ReactComponent as AudioOnLarge } from '../../assets/system-audio-on-large.svg';
import { ReactComponent as AudioOnSmall } from '../../assets/system-audio-on-small.svg';
import { ReactComponent as PlayLarge } from '../../assets/system-play-large.svg';
import { ReactComponent as ReplayLarge } from '../../assets/system-replay-large.svg';

const ThemedButton = styled.button`
  background: none;
  border: 0 none;
  color: var(${({ theme }): string => theme.colors.ui.primary});
  cursor: pointer;
  display: inline-block;
`;

const ToggleWrapper = styled.div`
  .large {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    .small {
      display: none;
    }

    .large {
      display: block;
    }
  }
`;

const ToggleSoundSVG: React.FC<{ isMuted: boolean }> = ({ isMuted }) => {
  return (
    <ToggleWrapper>
      {isMuted && (
        <>
          <AudioOffSmall height="24px" width="24px" className="small" />
          <AudioOffLarge height="48px" width="48px" className="large" />
        </>
      )}
      {!isMuted && (
        <>
          <AudioOnSmall height="24px" width="24px" className="small" />
          <AudioOnLarge height="48px" width="48px" className="large" />
        </>
      )}
    </ToggleWrapper>
  );
};

export const PlayButton: React.FC = () => {
  return (
    <ThemedButton>
      <PlayLarge height="48px" width="48px" />
    </ThemedButton>
  );
};

export const ReplayButton: React.FC = () => {
  return (
    <ThemedButton>
      <ReplayLarge height="48px" width="48px" />
    </ThemedButton>
  );
};

export const MuteButtonWrapper = styled.div`
  bottom: 0;
  position: absolute;
  right: 4%;
  z-index: 3;

  margin-block-end: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-block-end: var(${(props) => props.theme.responsive.spacing.xl});
  }
`;
export const MuteButton: React.FC<{
  toggleMuted: () => void;
  isMuted: boolean;
}> = (props) => {
  const { toggleMuted, isMuted } = props;

  return (
    <ThemedButton onClick={toggleMuted}>
      <ToggleSoundSVG isMuted={isMuted} />
    </ThemedButton>
  );
};

export const ButtonFullSizeWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;
