import * as React from 'react';
import { IS_MOBILE, MOBILE_NATIVE_CONTROLS_MAX_HEIGHT } from '../../../../environment';
import { Video } from '../../../../types';

interface UseVideoReturnValue {
  videoProps: React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > /* & { additional Props } */;
}

export const useVideo = (
  ref: React.MutableRefObject<HTMLVideoElement | null>,
  videoSettings: Video,
): UseVideoReturnValue => {
  const [isSwiping, setIsSwiping] = React.useState(false);

  const onClick: React.ReactEventHandler<HTMLVideoElement> = React.useCallback((event) => {
    if (videoSettings.nativeControls) {
      return;
    }

    // Prevent any device- or browser-default play/pause action.
    event.preventDefault();

    // Native mobile controls displayed at the top of the video (eg mute button) should not trigger a
    // playback toggle on tap/click. Such controls have safely predictable height in px, meaning we can
    // pay attention only to a tap/click event located outside of their zone.
    const ev = event as unknown as React.MouseEvent & React.TouchEvent;
    let canToggle = true;

    if (IS_MOBILE && ref.current?.controls) {
      const videoTop = ref.current.getBoundingClientRect().top;
      const relativeTouchY = (ev.clientY || ev.changedTouches.item(0).clientY) - videoTop;
      canToggle = relativeTouchY > MOBILE_NATIVE_CONTROLS_MAX_HEIGHT;
    }

    if (canToggle) {
      if (videoIsPlaying(ref.current)) {
        ref.current.pause();
      } else {
        ref.current.play();
      }
    }
  }, []);

  return {
    videoProps: {
      // ===================== START - DEFAULT HTML ATTRIBUTES =====================
      ...(IS_MOBILE
        ? {
            onTouchStart: () => setIsSwiping(false),
            onTouchMove: () => setIsSwiping(true),
            onTouchEnd: (ev) => {
              if (!isSwiping) onClick(ev);
              setIsSwiping(false);
            },
          }
        : {
            onClick,
          }),
      // ===================== END - DEFAULT HTML ATTRIBUTES =====================
    },
  };
};

function videoIsPlaying(videoElement: HTMLVideoElement) {
  return Boolean(
    videoElement.currentTime > 0 &&
      !videoElement.paused &&
      !videoElement.ended &&
      videoElement.readyState > 2,
  );
}
