import * as React from 'react';

export function useSeekingStatus(ref: React.MutableRefObject<HTMLVideoElement | null>) {
  const [isSeekingActive, setIsSeekingActive] = React.useState(false);

  React.useEffect(() => {
    function onSeekingChange() {
      setIsSeekingActive(true);
    }

    function onSeekedChange() {
      setIsSeekingActive(false);
    }

    ref.current?.addEventListener('seeking', onSeekingChange);
    ref.current?.addEventListener('seeked', onSeekedChange);

    return () => {
      ref.current?.removeEventListener('seeking', onSeekingChange);
      ref.current?.removeEventListener('seeked', onSeekedChange);
    };
  }, []);

  return isSeekingActive;
}
