export default function parseAspectRatio(input: string): number[] {
  const aspectRatio = input.split('/');

  if (aspectRatio.length !== 2) {
    throw Error(`Could not parse aspect ratio. Expect "x/y" but got "${input}".`);
  }

  const x = parseInt(aspectRatio[0], 10);
  const y = parseInt(aspectRatio[1], 10);

  if (Number.isNaN(x) || Number.isNaN(y)) {
    throw Error('Could not parse aspect ratio. Expect "x/y" to contain numbers.');
  }

  return [x, y];
}
