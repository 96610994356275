import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';

interface FeatureAppEnv {
  featureAppId: string;
  featureAppName?: string;
}

interface ContextState {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly videoService?: AudiVideoServiceInterfaceV1;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly videoService?: AudiVideoServiceInterfaceV1;
}

const ContextProvider = ({
  children,
  featureAppEnv,
  loggerService: logger,
  trackingService,
  videoService,
}: PropsWithChildren<ContextProviderProps>) => {
  const services = useMemo(() => {
    return {
      featureAppEnv,
      loggerService: logger,
      trackingService,
      videoService,
    };
  }, [featureAppEnv, trackingService, videoService, logger]);
  return <Context.Provider value={services}>{children}</Context.Provider>;
};
export default ContextProvider;
